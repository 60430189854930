import React from "react"
import Anchor from "../../core/components/anchor"
import Banner from "../../client/compositions/banner"
import Block from "../../core/components/block"
import Button from "../../client/compositions/button"
import RelatedProducts from "../../client/compositions/related-products"
import Decoration from "../../client/compositions/decoration"
import DecorationFluid from "../../client/compositions/decoration-fluid"
import Navigation from "../../core/components/navigation"
import Overlap from "../../client/compositions/overlap"
import Row from "../../core/components/row"
import Slider from "../../core/components/slider"
import { PortfolioTaglineMenu } from "../../client/compositions/tagline"
import { quickMenu } from "../../client/elements/menus"

/**
 * Project: WHotelAustin Page
 * @see https://zpl.io/bzgYOWl
 */
function ProjectWHotelAustinPage() {
  return (
    <article
      id="page-portfolio-whotel-austin"
      className="page-content portfolio-page light-quick-menu"
    >
      {quickMenu}

      <Banner
        title={
          <>
            <sup aria-hidden={true} role="presentation">
              Commercial
            </sup>
            <div>W Hotel</div>
          </>
        }
        content={<>Austin Residence, Austin, TX</>}
        className="flipped portfolio-banner"
      >
        <DecorationFluid image="portfolio-whotel-austin/banner.jpg" />
      </Banner>

      <Row id="summary" className="content-plus-media flipped">
        <Block className="block-content dropped">
          <div className="content">
            <dl className="details">
              <div className="detail">
                <dt className="title">Client</dt>
                <dd className="content">Marriott Hotels</dd>
              </div>
              <div className="detail">
                <dt className="title">Architects</dt>
                <dd className="content">
                  Andersson-Wise Architects (Austin, TX)
                </dd>
                <dd className="content">Boka Powell Architects (Austin, TX)</dd>
              </div>
            </dl>
            <p>
              Multi-wall glass partitions establish wine as the defining feature
              of this distinctive room design.
            </p>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-whotel-austin/content-01.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-one" className="media-plus-media">
        <Block className="block-media">
          <DecorationFluid image="/portfolio-whotel-austin/content-02.medium.jpg" />
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-whotel-austin/content-03.medium.jpg" />
        </Block>
      </Row>

      <Row id="features" className="content-plus-media">
        <Block className="block-content dropped">
          <h4 className="title">Features</h4>
          <div className="content">
            <ul className="features">
              <li className="feature">
                Private room design with transparent glass partition.
              </li>
              <li className="feature">
                Fully conditioned, multi-wall wine environment system.
              </li>
              <li className="feature">
                Minimal blackened steel display design.
              </li>
            </ul>
          </div>
        </Block>
        <Block className="block-media">
          <DecorationFluid image="/portfolio-whotel-austin/content-04.medium.jpg" />
        </Block>
      </Row>

      <Row id="gallery-two" className="single-media">
        <Block className="block-media">
          <Decoration image="/portfolio-whotel-austin/content-05.jpg" />
        </Block>
      </Row>

      <RelatedProducts
        id="related-products"
        products={[
          {
            slug: "inspire-free-standing",
            title: "Inspire Collection",
            url: "products/inspire/free-standing/",
          },
        ]}
      />

      <PortfolioTaglineMenu portfolioSlug="portfolio-whotel-austin" />
    </article>
  )
}

export default ProjectWHotelAustinPage
